<script>
/**
 * LOAD DEPENDENCIES
 * --------------------------------------------------------------------------------
 */
// import {csvParse} from "d3-dsv";
import {ref} from "vue";
import {useStore} from "vuex";

export default {
  setup(){
    const store       = useStore();
    const password    = ref("");
    const showSuccess = ref(null);
    const showError   = ref(null);

    const updatePassword = () => {
      store.dispatch("updatePassword", password.value)
      .then( () => {
        showSuccess.value = true;
        password.value = "";
        setTimeout(() =>  showSuccess.value = null, 3000)
      })
      .catch( () => {
        showError.value = true;
        setTimeout(() => showError.value = null, 3000)
      })
    }
    return {
      password,
      updatePassword,
      showSuccess
    }
  }
}
</script>

<template>
  <div>
    <transition name="fade">
      <p v-if="showSuccess" class="ob_alert success">La contraseña se ha actualizado</p>
    </transition>
  <form @submit.prevent="updatePassword">
    <p>
      <label>nueva contraseña (8-32 caracteres):</label>
      <input v-model="password" type="password" placeholder="contraseña" required minlength="8" maxlength="32" />
    </p>
    <p>
      <input type="submit" value="actualizar" />
    </p>
  </form>
  </div>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>